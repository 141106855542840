import React from 'react'

class TechStack extends React.Component {

  render() {
    return (
      <section className="binancestack technostack dev-tech-stack">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 text-left">
              <h3 className="heading-h2"><span className="bluecolor">Technologies Used</span> For our NFT Exchange Platform</h3>
              <p className="pharagraph">We at Coinsclone use a set of advanced technologies for creating and deploying error-free NFT Exchange Development Services.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
              <img className="logostck" width="573px" height="268px" src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.svg" alt="Technologies stock" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TechStack