import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis p-0 mb-0">                                                                                             
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>Coinsclone </span>- NFT Exchange Development Company </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone is best known for its NFT Exchange Development Services to investors. We fulfill the process of creating profitable NFT digital platforms where non-fungible tokens (NFTs) can be bought, sold, traded, and managed. Our skilled developer team develops an NFT Exchange platform that facilitates exchange transactions involving unique digital assets such as digital art, collectibles, virtual real estate, and more.
              </p>
              <p className="pharagraph text-center mb-0">Launch Your NFT Exchange Platform Today and Grab the Attention of Gen-Z Crypto Enthusiasts.
              </p>
            </div>  
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis