import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>Coinsclone for</span>
              NFT Exchange Development Services?</h3>
            <p className="pharagraph head">Coinsclone is a trusted choice for NFT Exchange platform development due to our extensive experience in blockchain technology and cryptocurrency markets.  Offering holistic features and functions to build a remarkable platform, Coinsclone stands out as a reliable partner for launching NFT Trading platforms. 
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Scalability.svg" alt="Scalability" />
                </div>
                <h3>
                  Scalability
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                Coinsclone offers scalability to the NFT platforms.  We curate a scalable architecture that ensures the platform can handle transaction volumes without experiencing any performance issues.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Agility and uniqueness.svg" alt="Agility and uniqueness" />
                </div>
                <h3>
                  Agility and uniqueness
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                We are committed to staying at the forefront of innovation. Coinsclone enables you to continuously innovate and differentiate your platform, ensuring long-term success in this dynamic NFT market.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Multi-tech-exposure.svg" alt="Multi-tech exposure" />
                </div>
                <h3> 
                  Multi-tech exposure
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                Our developer experts use multiple blockchain development technologies.  We are not limited to one specific technology stack or blockchain platform.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Whitelabel.svg" alt="Whitelabel" />
                </div>
                <h3>
                  Whitelabel
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                Our NFT exchange installation will be white label, which means, that after we have handed over the program, you can change it in any manner.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Multi-format minting.svg" alt="Multi-format minting" />
                </div>
                <h3>
                  Multi-format minting
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                Coinsclone offers versatility in token standards This flexibility allows you to explore alternative token standards or create one according to your specific use case. By this, we enhance the liquidity and accessibility of NFTs minted on your platform.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Multicurrency.svg" alt="Multicurrency" />
                </div>
                <h3>
                  Multicurrency
                </h3>
              </div>
              <div className="content-rght">
                <p className="pharagraph">
                Our NFT Exchange platform development supports multi-currencies feature that allows users from all over the world to use without any currency conversion. This expands your user base and increases liquidity within the NFT platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose