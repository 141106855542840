import React from 'react'



class WhyShould extends React.Component {


  render() {

    return (
      <section className="distin lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h2 className="heading-h2">NFT Exchange Development Services <span className="bluecolor">We Provide</span></h2>
          <p className="text-center">As an Excellent NFT Exchange Development company, We offer holistic NFT Exchange Development Services creating and launching digital marketplaces exclusively for non-fungible tokens (NFTs).</p>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/music.png" alt="NFT Exchange platform for Music" />
              <span>NFT Exchange platform for Music</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/art.png" alt="NFT Trading Platform for Artworks" /><span>NFT Trading Platform for Artworks</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/game.png" alt="NFT Trading Platform for Gaming" /><span>NFT Trading Platform for Gaming</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/sport.png" alt="NFT Trading Platform for Sports" /><span>NFT Trading Platform for Sports</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/wallet.png" alt="NFT Wallet Development" /><span>NFT Wallet Development</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/app.png" alt="NFT Exchange App Development" /><span>NFT Exchange App Development</span></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould