import React from 'react'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Basic Features</span> Of our NFT Exchange Development</h2>
               <p className="pharagraph head">Delve into our flexible features which are categorized into a spectrum of basic and advanced options. We make your business a profitable one.
            </p>
            </div>
          </div>
          <div className="row table-content orderflex mt-3">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Market place</h4>
                <p className="pharagraph">Develop your NFT collectible platform to buy and sell NFT in a decentralized environment with rare collections in the market. our platform offers a seamless trading experience to users
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Market-place.png" alt="Market place" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Secure-platform.png" alt="Robust Security" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Robust Security</h4>
                <p className="pharagraph">Create your NFT Exchange platform with multi-layered security to prevent your valuable NFT from hackers and scam artists. Our platform offers seed phrase recovery and external wallet support to make your users feel more secure. </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Cross-chain compatibility</h4>
                <p className="pharagraph">Our platform offers NFT cross-chain transfer to move your asset from one chain to another chain. This cross-chain compatibility makes NFT transfer easier and more convenient for the artist.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Cross-chain-compatibility.png" alt="Cross-chain compatibility" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Payments-made-easy.png" alt="Easy Payment Option" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Easy Payment Option</h4>
                <p className="pharagraph">Our NFT Trading platform development supports various payments.  The platform supports  crypto payments, credit cards, debit cards, and wire transfers for a hassle-free trading experience for collectors and creators at the same time.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 mb-0 ">
              <div className="top-left" >
                <h4 className="heading-h4">Smart contract audit service</h4>
                <p className="pharagraph">Coinsclone's Smart Contract auditing ensures that the NFT Smart Contract works flawlessly and is bug-free. Our NFT smart contract audit service is designed to identify vulnerabilities in smart contracts.</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/smart-contract-audit-service.png" alt="smart contract audit service" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures