import React from 'react'


const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3">Our NFT Exchange Platform <span className="bluecolor">Development Approach</span></span>
            </h3>
            <p className="pharagraph head">These are the processes followed by our experts to develop a superfine NFT Exchange Platform.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 text-center order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/project-analysis.svg" alt="project analysis" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Requirement Gathering</h5>
            <p  className="pharagraph">This is the initial stage, we would gather the requirements from clients and analyze them. We will provide the right solution for starting an NFT platform Development business.
            </p>
            <br />
            <h5>Planning </h5>
            <p  className="pharagraph">Our experts will derive a rough plan for NFT Exchange Development based on the business requirements of clients. We will then set the goals, objectives, and deadlines of the project.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5>Designing</h5>
            <p  className="pharagraph">Our impeccable team of creative designers will create a user-friendly design infrastructure for the NFT Exchange platform development. Based on the needs of the client, we will create a Layout.  The UI/UX design is incorporated only after confirming with the client.
            </p>
            <br />
            <h5>Development</h5>
            <p  className="pharagraph">In this stage, our skilled developers will work on the backend part and incorporate the needed features and functionalities.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 text-center order1">
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/designing-development.svg" alt="Designing and development" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12 text-left order1" >
            <img width="384px" height="264px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/testing-deployment.svg" alt="Testing and deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5>Testing</h5>
            <p  className="pharagraph">After the development stage, our testing team will check the quality of the NFT Exchange Development by conducting various tests. If any functionality doesn’t work properly, then our team will rectify the issue immediately.
            </p>
            <br />
            <h5>Deployment</h5>
            <p  className="pharagraph">Our NFT Exchange platform development team will finally deploy the exchange platform into the server as per the client’s request.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach