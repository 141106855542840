import React, { Component } from 'react'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Advanced Features</span> Integrated in</span>
            Our NFT Exchange Platform</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Quick Filters
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Customized UI/UX
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Ranking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Wallet connectivity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Liquidity
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Auction
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  NFT Exchange App
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Multi-language support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Fees settings
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Fractional Trading
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Quick Filters
                        </h4>
                        <p className="pharagraph">Our smart filter feature lets your buyers explore various collections from music, photography, domain names, trading cards, utility, virtual world, etc. Lets your user make a quick buying decision on their favorite collectibles.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Quick Filters.png" alt="Quick Filters" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Customized UI/UX</h4>
                        <p className="pharagraph">Our NFT Exchange platform comes with a completely customized UI/UX interface designed to be more responsive and engaging for your users. We design the UI/UX that works hassle-free across different platforms and devices.
                        </p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Tailor made UI-UX.png" alt="Customized UI/UX" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Ranking</h4>
                        <p className="pharagraph">One of the core features that we incorporated in the NFT platform development is ranking the Total sold tokens, volume, price, and other statistical data that help in listing NFTs. This will help creators and buyers identify the demand & position of their assets in the marketplace.
                        </p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Ranking.png" alt="Ranking" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Wallet connectivity
                        </h4>
                        <p className="pharagraph">Our NFT Trading development comes with the ability to connect with various wallet providers to store the collection in the secure wallet of the user's choice and also in a cold wallet for more secure storage.
                        </p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="380" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Wallet connectivity.png" alt="Wallet connectivity" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Liquidity</h4>
                        <p className="pharagraph">Through our NFT platform development, you can make NFTs available for the mass audience for instant trade and easily available for quick asset transfer. This is an essential factor when it comes to investing in NFTs.  
                        </p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Liquidity.png" alt="Liquidity" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Auction</h4>
                        <p className="pharagraph">Our skilled developer team will integrate the auction feature with the NFT Trading Platform. You can create an auction by setting up a few things such as base price, highest price, and auction duration</p></div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Auction.png" alt="Auction" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">NFT Exchange App</h4>
                        <p className="pharagraph">To make it more user-friendly, our NFT Marketplace developers offer seamless support on both Android and iOS devices. This feature ensures accessibility to users worldwide. With our app, you can easily buy, sell, and store NFT assets directly in your mobile wallet.</p></div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/NFT app development.svg" alt="NFT Exchange App" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-language support</h4>
                        <p className="pharagraph">To expand the reach of our platform to a global audience, our team of NFT developers has implemented multilingual support. By offering support in multiple languages, we aim to make your platform more accessible and inclusive to users from diverse backgrounds.</p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Fees settings.png" alt="Multi-language support" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Fees settings</h4>
                        <p className="pharagraph">We provide advanced fee settings on our NFT Exchange platform, allowing customizable fee structures for transactions. Structurize the fees for minting and listing the token on the platform and gas fees for network transactions etc. customized to your preferences for enhanced flexibility and control.</p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Multi Lingual.png" alt="Fees settings" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Fractional trading</h4>
                        <p className="pharagraph">The fractional trading feature in our NFT Marketplace development allows the user to divide high-value NFT assets into smaller, tradable fractions or shares. This enables investors to buy portions of expensive NFTs, thus making them more accessible to a wider range of investors.</p> </div>
                    </div>
                    <div className="col-md-6 right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftexchange/Fractional trading.svg" alt="Fractional trading" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures